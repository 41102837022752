//
// Button Overrides
//

.btn-block {
    display: block;
    width: auto;
    max-width: max-content;
    min-width: 13.25em;
}

.glyphicon.btn-primary {
    font-family: Glyphicons Halflings;
}
