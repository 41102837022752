// inset style used throughout

@mixin inset() {
    background: rgba($white, 0.8);
    border: 1px solid  rgba(0,0,0,0.09);
    padding: 20px;
    border-radius: 4px;
}

.tn-events-filter-component,
.tn-listing-component,
.tn-calendar-component,
.tn-cart-component,
.tn-listing-legend-container {
    @include inset();
}

.page-wrap {
    .tn-heading {
        text-align: center;
        padding: 0 0 20px 0;
        @media (max-width: $screen-xs-max) {
        }
    }
}

@mixin tn-link {
    @include ff-bold;
    outline: 0!important;
    text-transform: uppercase;
    letter-spacing: .115rem;
    color: $boston-grey;
    transition: $transition;
    &:hover, &:active {
        color: $brand-primary;;
    }
    a {
        transition: $transition;
        color: $boston-grey;
        text-decoration: none;
        &:hover, &:active {
            color: $brand-primary;;
        }
    }
}

// links in the page body

.content-wrap p a {
    text-decoration: underline;
    color: $brand-primary;
    text-transform: uppercase;
    // font-family: Brown-Pro-Bold;
    letter-spacing: 1px;
    outline: 0!important;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    font-size: .875em;
    &:hover,
    &:focus {
        color: $boston-grey;
    }
}

@mixin underline-anim {
    position: relative;
    &:before {
        content: '';
        height: 1px;
        width: 0;
        background: $boston-grey;
        position: absolute;
        z-index: 1;
        bottom: -2px;
        left: 0;
        transition:width .3s ease;
        transition-delay: .35s;
    }
    &:after {
        content: '';
        height: 1px;
        width: 100%;
        background: $boston-grey;
        position: absolute;
        bottom: -2px;
        right: 0;
        transition: width .5s ease-out;
    }
    &:hover,
    &:focus,
    &.focus {
        &:before {
            width: 100%;
        }
        &:after {
            width: 0;
        }
    }
}
