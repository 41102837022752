.c-footer {
    background-color: $boston-grey;
    padding: 2% 0 2% 0;
    @include ff-light;
    position: relative;
    z-index: 0;
    margin-top: 80px;
    &:after {
        content: "";
        position: absolute;
        background: $boston-grey;
        transform: skewY(-3deg);
        width: 100%;
        height: 26.25rem;
        top: -10%;
        z-index: -1;
        @media (min-width: $screen-md-max) {
            top: -20%;
        }
    }

}

.c-footer__menu {
    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 5em;
        li {
            @include tn-link;
            &:not(:first-of-type) {
                margin-left: 1.5em;
            }
            a {
                color: $white;
            }
        }
    }
}

.c-footer__main-container, .c-footer__lower-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.c-footer__lower-container {
    align-items: flex-end;
    margin-top: ($padding-base-horizontal * 2);
    min-height: 156px;
    margin-bottom: $padding-base-horizontal;
}

.c-footer__social {
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        li {
            list-style-type: none;
            &:not(:first-of-type) {
                margin-left: 1em;
            }
        }
        img {
            width: 41px;
            height: 41px;
            max-width: 100%;
            height: auto;
            -ms-interpolation-mode: bicubic;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.c-footer__social-newsletter {
    margin-top: $padding-base-horizontal;
    text-align: right;
    p {
        color: $light-bg;
    }
}

.c-footer__legal {
    color: $light-bg;
    a {
        color: $light-bg;
        padding: 0;
        font-weight: lighter;
        &:not(:last-of-type) {
            &:after {
                content: '|';
                margin: 0 1.5em;
                line-height: 1em;
                vertical-align: text-top;
                opacity: .5;
            }
        }
    }
    p {
        margin: 0;
    }
}

.c-footer__address {
    color: $white;
    text-align: right;
    address, a {
        color: $light-bg;
    }
    address {
        margin-bottom: 0;
    }
    &-tel, a[href^="tel"] {
        display: block;
        text-decoration: none;
        color: inherit;
    }
}

.c-footer__legal-nav, .c-footer__address-tel {
    margin-bottom: $padding-base-horizontal;
}


//
// Mobile Footer
//

@media (max-width: $screen-xs-max) {
    .c-footer__main-container, .c-footer__lower-container {
        flex-direction: column;
    }
    .c-footer__menu ul {
        padding-right: 0;
    }
    .c-footer__lower-container, .c-footer__main-container {
        align-items: center;
    }
    .c-footer__address {
        text-align: center;
    }
    .c-footer__main-container {
        margin-bottom: 5%;
    }
    .c-footer__address, .c-footer__social, .c-footer__disclaimer {
        margin-top: 5%;
    }
    .c-footer__address-tel, .c-footer__legal-nav, .c-footer__social-newsletter, .c-footer__legal-exemption {
        text-align: center;
    }
}

//
// Very small screens
//

@media (max-width: 375px) {
    .c-footer__menu ul li {
        font-size: 80%;
    }
    .c-footer__social ul img {
        width: 30px;
    }
}
