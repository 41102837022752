.tn-detail-page-header {
    padding: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    .tn-detail-title {
        font-size: 250%;
        padding: 0;
    }
    .tn-detail-image {
        display: none;
    }
}
.tn-detail {
    order: 1;
    .tn-detail-selected-date {
        font-size: 160%;
        display: block;
        float: none;
    }
    .tn-detail-performance-title { // repeated, hide
        display: none;
    }
}
.tn-detail-date-list {
    order: 2;
}

//
// Reorganise so that Additional events container is last & full-width
//
.tn-events-detail-page {
    .tn-event-detail__additional-events-container {
        min-width: 250px;
    }
    @media (min-width: 1000px) {
        .tn-event-detail {
            flex-wrap: wrap;
            position: relative;
            .tn-event-detail__performance-details-container {
                max-width: 66%;
            }
            .tn-event-detail__additional-events-container {
                order: 3;
                position: absolute;
                right: 0;
                top: 0;
                width: 30%;
                margin-right: 0;
            }
        }
    }
}

.tn-prod-season-header__title {
    @include ff-thin;
}

.tn-event-detail__additional-events-container .tn-additional-events__continue-shopping-link {
      @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
      @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $border-radius-base);
}

//
// Select Your Own Seat
//

.tn-syos, .tn-syos--viewing-seats .tn-syos {

    //
    // Section Select
    //

    .tn-syos-screen-container__button-list {
        .tn-syos-screen-button {
            @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
            h4 {
                color: $white;
            }
            &:disabled,
            &[disabled]{
                opacity: 0.35;
                cursor: not-allowed;
            }
        }
    }

    //
    // Seatmap
    //

    .tn-syos.tn-syos--seats-selected {
        .tn-syos__btn-add-to-cart {
            @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
        }
    }

    .tn-syos__layout-container {
        border: 1px solid #c8c8c8;
    }
    .tn-syos-screen-container__button-list-container, .tn-syos-screen-container__map-container {
        background: #fff;
    }
    .tn-syos-screen-container__button-list-container {
        background: #f5f5f5;
        border-width: 0 0 0 0;
        @media (max-width: 768px) {
            padding: 1rem;
        }
    }
    .tn-syos-mobile-cart-controls {
        border-width: 1px 0;
    }
    .tn-syos-mobile-cart-controls__item:first-child, .tn-syos-mobile-cart-controls__item:first-child {
        border-left-width: 0;
    }
    .tn-syos-mobile-cart-controls__item, .tn-syos-mobile-cart-controls__item {
        border-width: 1px 0 0;
    }

    .tn-syos-screen-container__button-list-heading {
        // color: $brand-primary;
    }
    .tn-syos-mobile-cart-controls__item {
        border-bottom-width: 0;
    }

    .tn-syos__btn-add-to-cart {
        background-color: $brand-primary;
        border-color: transparent;
        &:disabled {
            background-color: #ccc;
            color: #f2f2f2;
        }
    }

}

//
// Purchase Best Avaliable
//

.tn-ticketing-mode-change {
    .tn-ticketing-mode-change__content {
        padding: 0;
        border: 0;
        .tn-ticketing-mode-change__anchor {
            font-style: normal;
            @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
            @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $border-radius-base);
        }
    }
}

//
// Very small screens
//
@media (max-width: 375px) {
    .tn-ticketing-mode-change .tn-ticketing-mode-change__content .tn-ticketing-mode-change__anchor {
        font-size: 75%;
    }
    .tn-syos {
        .tn-syos__btn-add-to-cart {
            font-size: 90%;
        }
        .tn-syos-mobile-cart-controls__summary-subtotal {
            font-size: 90%;
        }
        .tn-syos__btn-add-to-cart--cart-control {
            padding: 0 5px;
        }
    }
}
