//
// Subnav Component
//

.tn-subnav-component {
    // border-bottom: 1px solid $boston-light-grey;
    padding-bottom: 25px;
    @media (max-width: $screen-xs-max) {
        padding-top: 25px;
    }
    .tn-text {
        @include ff-bold;
    }
    .tn-promo-box {
        margin-right: 0;
    }
}

.tn-subnav-component .tn-cart-link, .tn-subnav-component .tn-login-link, .tn-subnav-component .tn-promo-box {
    @include tn-link;
}

.tn-subnav-component .tn-cart-link.tn-active .tn-timer {
    // No hover or interaction change for timer
    color: $text-color !important;
}

#tn-subnav-promo-code {
    background: 0 0;
    box-shadow: inset 0 1px 2px $boston-light-grey;
    transition: box-shadow .5s,border-color .25s ease-in-out;
    border: 1px solid $boston-grey;
    outline: 0 !important;
    color: $text-color;

    padding-left: 0.5em;
    height: 34px;
}

#tn-apply-promo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tn-subnav-component .tn-promo-box button.tn-subnav-promo-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-decoration: none;
    font-size: 0;
    padding-right: 2.5em;
    height: 34px;
    width: 36px;
    background-color: $brand-primary;
    position: relative;
    border: 1px solid $boston-grey;
    border-width: 1px 1px 1px 0;
    &:before {
        content: "\E080";
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        font-size: 16px;
        font-family: Glyphicons Halflings;
        font-style: normal;
        color: $white;
    }
}

@media (max-width: 768px) {
    .tn-subnav-component {
        .tn-login-link.tn-logged-in .tn-logged-in-email {
            display: none;
        }
        .tn-login-link.tn-logged-in .tn-logout-link:before {
            display: none;
        }
        .tn-logout-link {
            .tn-text {
                display: none;
            }
        }
    }
}

@media  only screen and (min-width:768px) and (max-width: 992px) {
    .subnav-icon, .subnav-text, .tn-subnav-component .tn-cart-link .tn-timer, .tn-subnav-component .tn-icon, .tn-subnav-component .tn-login-link.tn-logged-in .tn-logged-in-email, .tn-subnav-component .tn-login-link.tn-logged-in .tn-logged-in-text, .tn-subnav-component .tn-text {
        font-size: 1em;
        line-height: 1em;
    }
    .subnav-icon, .tn-subnav-component .tn-icon {
        font-size: 1em;
    }
}
