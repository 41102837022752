@font-face {
    font-family: 'Brown-Pro-Light';
    src: url('../static/fonts/lineto-brown-pro-light.woff') format('woff'),
         url('../static/fonts/lineto-brown-pro-light.eot') format('eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brown-Pro-Thin';
    src: url('../static/fonts/lineto-brown-pro-thin.woff') format('woff'),
         url('../static/fonts/lineto-brown-pro-light.eot') format('eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brown-Pro-Bold';
    src: url('../static/fonts/lineto-brown-pro-bold.woff') format('woff'),
         url('../static/fonts/lineto-brown-pro-bold.eot') format('eot');
    font-weight: normal;
    font-style: normal;
}


@mixin ff-thin {
    font-family: 'Brown-Pro-Thin', sans-serif;
    font-weight: normal;
    font-style: normal;
}
@mixin ff-light {
    font-family: 'Brown-Pro-Light', sans-serif;
    font-weight: normal;
    font-style: normal;
}
@mixin ff-bold {
    font-family: 'Brown-Pro-Bold', sans-serif;
    font-weight: normal;
    font-style: normal;
}
