.tn-prod-listing {
    a {
        color: $text-color;
    }
}

.tn-prod-list-item__property--heading a {
    color: $text-color;
    @extend .h2;
}

.tn-perf-listing-action {
    @extend .btn;
    @extend .btn-primary;
    text-align: center;
}

.tn-events-filter-component {
    font-size: 80%;
    .row:first-of-type {
        border-bottom: 1px solid darken($light-bg, 10%);
    }
    h3 {
        font-size: 100%;
    }
}

.tn-listing-results-calendar {

}
.fc-event {
    border-radius: 2px;
    font-size: 80%;
    color: $white;
    background: $brand-success;
    &:hover {
        color: $white;
        background: lighten($brand-success, 10%);
    }
}

#tn-events-list-view {
    .tn-btn-datepicker__icon-container {
        color: inherit;
    }
    .tn-prod-list-item {
        background: #f5f5f5;
        border-color: transparent;
        @media (max-width: $screen-xs-max) {
            padding: ($padding-base-horizontal * 2) $padding-base-vertical $padding-base-horizontal;
        }
    }
}

#tn-events-calendar-view {
    .tn-btn-datepicker__icon-container {
        color: inherit;
    }
}

.tn-event-listing__primary-views-container {
    border-width: 1px;
    .tn-event-listing-mode-tab-nav__list {
        border-width: 1px;
    }
    .tn-event-listing-mode-tab-nav__list-item {
        color: $black;
        border-width: 1px;
        &.active {
            margin-bottom: -1px;
        }
    }
}
.tn-datepicker td.day[aria-current=date] span {
    border: transparent;
    background: $brand-primary;
}
@media (min-width: $screen-tablet) {
    .tn-events-list-view__date-range {
        flex-wrap: wrap;
        flex-direction: column;
        .tn-events-list-view__btn-reset-dates-container {
            padding: 15px 0 0 0;
        }
    }
}

@media (min-width: 1320px) {
    .tn-events-list-view__date-range {
        flex-wrap: wrap;
        flex-direction: row;
        .tn-events-list-view__btn-reset-dates-container {
            padding: 0 0 0 20px;
        }
    }
}
