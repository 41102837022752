
.max-width {
    @media (max-width: $screen-xs-max) {
        padding: 0 5%;
    }
    @media (min-width: $screen-xs-max) {
        width: 80%;
    }
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.global-header {
    padding: 60px 0 30px;
    text-align: center;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.page-wrap {
    background-color: $page-background;
}
.content-wrap {
    background: $page-background;
    padding-bottom: 50px;
    min-height: 90vh;
    @media (min-width: $screen-xs-max) {
        padding: 5% 0%;
    }
}

