.c-header > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.c-header {
    padding: 1em 0 0;
    position: relative;
    margin-bottom: 2em;
    width: 100%;
    z-index: 999;
    &:after {
        content: '';
        position: absolute;
        background: $boston-light-grey;
        transform: skewY(-3deg);
        width: 100%;
        height: 12.5em;
        z-index: -1;
        transition: height 350ms ease-in-out;
        top: -4em;
    }
}

.state-menu--open {
    .c-header:after {
        top: -2em;
        height: 40.25rem;
    }
    .c-header__nav ul {
        display: flex;
        position: absolute;
        margin-top: 12.5em;
        top: -4em;
        left: 0;
        width: 100%;
        text-align: center;
        li {
            margin: 1em 0;
        }
    }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
 100% {
    opacity: 1;
  }
}

.c-header__nav ul li {
  animation: FadeIn 1s ease-in-out;
  animation-fill-mode: both;
}
.c-header__nav ul li {
    &:nth-child(1) { animation-delay: 0.25s }
    &:nth-child(2) { animation-delay: 0.5s }
    &:nth-child(3) { animation-delay: 0.75s }
}

.brand-logo-link {
    width: 100%;
    img {
        max-width: 10.625em;
        margin-top: 1.5em;
        width: 100%;
    }
}

#c-mobile-nav__trigger {
    display: none;
    cursor: pointer;
    @media (max-width: 768px) {
        display: flex;
        align-items: center;
    }
    .c-mobile-nav__text {
        @include tn-link;
        padding-right: 2.65rem;
    }
    .c-mobile-nav__menu svg {
        width: 1.5em;
        top: .3em;
        height: .625em;
    }
    .c-mobile-nav__closed {
        transform: rotate(-180deg);
        transition: all .5s ease;
        svg {
            transform: translateY(5px);
        }
    }
}

nav {
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style-type: none;
        }
    }
}

.c-header nav ul {
    @media (max-width: 768px) {
        display: none;
    }
    display: flex;
    flex-direction: column;
    text-align: right;
    @media (min-width: $screen-xs-max) {
        flex-direction: row;
        text-align: left;
    }
    li {
        margin-left: 1em;
        @include tn-link;
    }
}

//
// Very small screens
//
@media (max-width: 375px) {
    .brand-logo-link img {
        max-width: 100px;
    }
    .c-header nav ul li {
        font-size: 90%;
    }
    .c-header:after {
        top: -6em;
    }
    .state-menu--open {
        .c-header__nav ul {
            top: -6em;
        }
    }
}
