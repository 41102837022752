//
// Cart
//

.tn-cart-details-page {
    .tn-cart-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (min-width: $screen-md-max) {
            margin-top: 60px;
        }
    }

    .tn-empty-cart-message {
        margin-bottom: 0px;
    }

    .tn-cart-buttons__primary-action {
        margin-bottom: 0;
    }
    .tn-cart-buttons__secondary-action {
        @include tn-link;
    }
    .tn-cart-item-detail__list {
        padding: $padding-base-vertical 0;
    }
    .tn-cart-item:first-of-type {
        border-top: 0;
    }
}
