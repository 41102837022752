#TNEWCUSTOM-checkout-ask-component {
    border-bottom: 1px solid rgba(0,0,0,0.09);
}
.tn-gift-certificates-redemption-component {
    padding: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.09);
}

.tn-checkout-survey-component {
    padding: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.09);
}

.tn-payment-submit-component {
    padding: 15px 0;
}

.tn-payment-page {
    h1.tn-heading {
        @extend .h2;
    }
}
